jQuery(function($){


  const setCustomProp = () => {

    let _gh_H = Math.floor( $('.global-header').outerHeight() ) || 0;
    let _gh_in_H = Math.floor( $('.global-header-inner').outerHeight() ) || 0;
    let _gh_nv_H = Math.floor( $('.global-nav').outerHeight() ) || 0;
    let _todays_item_H = Math.floor( $('.hasToday').siblings('.article-list').children('li').outerHeight() ) || 0;

    document.documentElement.style.setProperty('--gh-H', _gh_H + 'px', '');
    document.documentElement.style.setProperty('--gh-in-H', _gh_in_H + 'px', '');
    document.documentElement.style.setProperty('--gh-nv-H', _gh_nv_H + 'px', '');
    document.documentElement.style.setProperty('--todays-item-H', _todays_item_H + 'px', '');
  }
  window.addEventListener('load', setCustomProp);
  window.addEventListener('resize', setCustomProp);
  window.addEventListener('orientationchange', setCustomProp);
  setCustomProp();

  let _ranking_H = Math.floor( $('.ranking-wrap .js-tabs-content').outerHeight() ) || 0;
  document.documentElement.style.setProperty('--ranking-H', _ranking_H + 'px', '');


  (() => {
    $('.has-fullimg img').on('contextmenu selectstart mousedown', function(){
      return false;
    });
  })();

  (() => { // loading 表示
    $('.thumb').each( (i,e) => {
      $(e).addClass('loading');
    });
    $(window).on('load', function(){
      $('.loading').removeClass('loading');
    });
  })();

  (() => { // TB時 2段組み並び替え
    if ( $('.sub-inner').data('variable') ) {
      var _subarr = $('.sub-inner').data('variable').split('|');
      $.each(_subarr, function(i, v){
        $('.order[data-subindex="'+v+'"]').css( {'order': (i+1)} );
      });
    }
  })();

  _replace_tabs($);
  _filter_tabs($);
  _selecttag($);
  _tabs($);
  _gallery_modal($);
  _gallery_within_article($);
  _gallery($);
  _anchor($);
  _tab_carousel($);
  _ticker($);
});

$.easing.easeOutCirc = function (x, t, b, c, d) {
  return c * Math.sqrt(1 - (t=t/d-1)*t) + b;
};
$.easing.easeInOutExpo = function(x, t, b, c, d) {
  if (t==0) return b;
  if (t==d) return b+c;
  if ((t/=d/2) < 1) return c/2 * Math.pow(2, 10 * (t - 1)) + b;
  return c/2 * (-Math.pow(2, -10 * --t) + 2) + b;
};

let _replace_tabs = ($) => {
  let _replace_tabs = $('.js-replace-tabs');

  _replace_tabs.each( (i,tab) => {
    let _replace_list = $(tab).siblings('.js-replace-list').find('> *');

    let _holdtab = ( $(tab).hasClass('area-select') && sessionStorage.getItem('kbds-mynews-holdtab') ) ? sessionStorage.getItem('kbds-mynews-holdtab') : 0;

    $(tab).find('li').removeClass('current').eq(_holdtab).addClass('current');
    _replace_list.removeClass('current').eq(_holdtab).addClass('current');

    $(tab).find('li').on('click', (cl) => {
      let _index = $(cl.currentTarget).index();

      if ( $(tab).hasClass('area-select') ) {
        sessionStorage.setItem('kbds-mynews-holdtab', _index);
      }

      $(cl.currentTarget).siblings().removeClass('current');
       _replace_list.siblings().removeClass('current');

      $(cl.currentTarget).addClass('current');
      _replace_list.eq(_index).addClass('current');

      // history.pushState('', '', '#'+_index);
    });
  });
}
let _filter_tabs = ($) => {
  let _filter_tabs = $('.js-filter-tabs');

  _filter_tabs.each( (i,e) => {
    let _filter_list = $(e).siblings('.js-filter-wrap').find('ul');
    $(e).find('ul li').eq(0).addClass('current');

    $(e).find('ul li').on('click', (cl) => {
      if( _filter_list.length == 0 ) {
        console.log('no contents!');
      }

      let _index = $(cl.currentTarget).index();

      $(cl.currentTarget).siblings().removeClass('current');
      _filter_list.siblings().removeClass('current');

      // history.pushState('', '', '#'+_index);
    });
  });
}
let _selecttag = ($) => {
  let _fl = 0;
  let _tag = 'すべて';
  let _filter_tabs = $('.js-filter-tabs');

  _filter_tabs.each( (i,e) => {
    $(e).find('ul li').on('click', (cl) => {
      let _fl = 1;
      let _this = $(cl.currentTarget).closest(_filter_tabs);
      let _tag = $(cl.currentTarget).text();

      $(cl.currentTarget).find('.current').removeClass('current');
      $(cl.currentTarget).addClass('current');
      let _filter_list = _this.siblings('.js-filter-wrap').find('ul');

      let _n = 0;
      let _hide = () => {
        setTimeout( () => {
          if ( _filter_list.find('li[data-tag*="'+_tag+'"]').eq(_n).addClass('hide'), _n++, 9 > _n ) {
            _hide();
          } else {
            clearTimeout(_fl);
            _filter_list.find('li').addClass('hide');

            _fl = setTimeout( () => {
              _filter_list.find('li').hide();
              _filter_list.find('li[data-tag*="'+_tag+'"]').show();
              _n = 0
              _show();
            }, 200)
          }
        }, 30);
      };
      let _show = () => {
        setTimeout( () => {
          if ( _filter_list.find('li[data-tag*="'+_tag+'"]').eq(_n).removeClass('hide'), _n++, 9 > _n ) {
            _show();
          } else {
            clearTimeout(_tag);
            _filter_list.find('li[data-tag*="'+_tag+'"]').removeClass('hide');
            _tag = setTimeout( () => {
              _fl = 0
            }, 200);
          }
        }, 30);
      };
      return _hide();
    });
  });
}
let _tabs = ($) => {
  let _tabs = $('.js-tabs');

  _tabs.each( (i, tab) => {
    let _js_tabs_content = $(tab).parent().children('.js-tabs-content');

    $(tab).find('li').eq(0).addClass('current');
    _js_tabs_content.eq(0).addClass('current');

    $(tab).find('li').on('click', (cl) => {
      let _index = $(cl.currentTarget).index();

      $(cl.currentTarget).siblings().removeClass('current');
      _js_tabs_content.siblings().removeClass('current');

      $(cl.currentTarget).addClass('current');
      _js_tabs_content.eq(_index).addClass('current');
    });
  });
}
let _gallery_modal = ($) => {
  $('.gallery li:not(:has("a"))').css('cursor','pointer').on('click', (cl) => {

    let _scrlPos = $( window ).scrollTop();
    let _index = $(cl.currentTarget).index();

    $('.np-modal, .knockOut').remove();
    $('body').css({'overflow':'hidden'}).prepend('<div class="np-modal"><span class="modalClose"></span><div class="modalInner"></div></div><div class="knockOut"></div>');
    $('.knockOut').css({'display':'block'});

    let _insertPhoto = $(cl.currentTarget).closest('ul').clone().prop('class','').addClass('splide__list');

    let _insertThumb = $(cl.currentTarget).closest('ul').clone().prop('class','').addClass('splide__list');

    $('.modalInner')
      .prepend('<div class="gallery-photo splide"><div class="splide-wrapper"><div class="splide__track"></div></div></div>')
      .append('<div class="gallery-thumb splide"><div class="splide__track"></div></div>');

    $('.splide__track', '.gallery-photo').html(_insertPhoto);
    $('.splide__track', '.gallery-thumb').html(_insertThumb);

    $('.splide__list').find('li').addClass('splide__slide');

    $('.gallery-photo', '.np-modal').find('img').each( (i, img) => {
      $(img).attr('src', $(img).attr('src').replace(/\/[a-z]_/g, '/a_')).on('contextmenu selectstart', () => { return false; });
      $(img).after('<p>' + $(img).attr('alt') + '</p>');
    });

    $('.np-modal').addClass('mopen');

    let _galleryPhotoTarget = '.gallery-photo';
    let _galleryPhotoOptions = {
      mediaQuery: 'min',
      type: 'fade',
      pagination: false,
      arrows: true,
      start: _index,
      rewind: true,
      rewindSpeed: 1000,
    }
    let _galleryPhotoSplide = new Splide(_galleryPhotoTarget, _galleryPhotoOptions);

    let _galleryThumbTarget = '.gallery-thumb';
    let _galleryThumbOptions = {
      type: 'slide',
      mediaQuery: 'min',
      gap: 8,
      arrows: false,
      pagination: false,
      isNavigation: true,
      perPage: 7,
      start: _index,
      updateOnMove: true,
      focus: 'center',
      rewind: true,
      rewindSpeed: 1000,
      trimSpace: 'move',
      breakpoints: {
        640: {
          perPage: 7
        }
      },
    }
    let _galleryThumbSplide = new Splide(_galleryThumbTarget, _galleryThumbOptions);

    _galleryPhotoSplide.sync(_galleryThumbSplide);
    _galleryPhotoSplide.mount();
    _galleryThumbSplide.mount();

    $('.knockOut, .modalClose').on('click', () => {
      _modalClose();
    });
    let _modalClose = () => {
      $('html, body').prop({scrollTop: _scrlPos});
      $('.np-modal').removeClass('mopen').remove();
      $('.knockOut').fadeOut(500).queue( function() {
        $(this).css({'display':'none'}).remove().dequeue();
      });
      $('body').prop({'style':''});
    };
  });
}
let _gallery = ($) => {
  if( $('.gallery-wrap').length > 0 ) {
    let _url  = new URL(location);
    var _photoPageNum = _url.searchParams.get('p');
    var _iniphoto = $('li', '.gallery-wrap');
    var _photoLength = _iniphoto.length;

/*     $('.gallery-wrap').prepend('<figure class="current-photo"><div class="thumb"><img src=""></div><figcaption></figcaption></figure>');
    $('.gallery-wrap').find('.gallery').removeClass('gallery').addClass('gallery-thumb'); */

    var _photoNum = ( _photoPageNum !== null ) ? _photoPageNum : 0;
    let _imgAttr = [
      _iniphoto.eq(_photoNum).find('img').attr('src').replace(/\/[a-z]_/g, '/a_'),
      _iniphoto.eq(_photoNum).find('img').attr('alt')||null,
      _iniphoto.eq(_photoNum).find('a').attr('href'),
    ];
/*     $('.current-photo .thumb img').attr('src', _imgAttr[0]).on('contextmenu selectstart mousedown', function(){
      return false;
    });
    $('.current-photo figcaption').html(_imgAttr[1]); */

    _iniphoto.eq(_photoNum).addClass('current');
    _iniphoto.each( function(i) {
        $(this).find('a').attr('href', $(this).find('a').attr('href') + '?p=' + i );
    });

/*     if ( _photoNum > 0 ) {
      $('.current-photo .thumb').before('<a href="'+ _imgAttr[2] +'?p='+ (parseInt(_photoNum) - 1) +'" class="prev"></a>');
    }
    if ( _photoNum < _photoLength - 1 ) {
      $('.current-photo .thumb').after('<a href="'+ _imgAttr[2] +'?p='+ (parseInt(_photoNum) + 1) +'" class="next"></a>');
    } */
  }
}
let _gallery_within_article = ($) => {
  if ( $('.gallery-within-article').length > 0 ) {

    $('.gallery-within-article').each(function(){
      let _this = $(this);
      let __this = $(this).find('ul');

      // initial photo
      let _iniphoto = $('li', __this);
/*       let _photohref = _iniphoto.eq(0).find('a').attr('href');
      let _imgAttr = [
        _iniphoto.eq(0).find('img').attr('src').replace(/\/[a-z]_/g, '/c_'),
        _iniphoto.eq(0).find('img').attr('alt')||null,
        _iniphoto.find('a').attr('href'),
      ];

      if ( _this.hasClass('notlinks') !== false ) {
        _this.prepend('<figure class="current-photo"><span class="thumb"><img src="'+ _imgAttr[0] +'"></span><figcaption>'+ _imgAttr[1] +'</figcaption></figure>');
      } else {
        _this.prepend('<figure class="current-photo"><a href="'+ _imgAttr[2] +'?p=0" class="thumb"><img src="'+ _imgAttr[0] +'"></a><figcaption>'+ _imgAttr[1] +'</figcaption></figure>');
      } */

      _iniphoto.each( (i,e) => {
        $(e).on('click', function(cl){

          cl.preventDefault();
          let _index = $(e).index();

          let _photohref = $(e).eq(0).find('a').attr('href');
          let _imgAttr = [
            $(e).find('img').attr('src').replace(/\/[a-z]_/g, '/c_'),
            $(e).find('img').attr('alt') || null,
            $(e).find('a').attr('href'),
          ];

          $('.current-photo .thumb img', _this).attr('src', _imgAttr[0]);
          $('.current-photo figcaption', _this).html(_imgAttr[1]);
/*           obj = document.getElementsByTagName('figcaption');
          obj[0].textContent = _imgAttr[1]; */
          $('.current-photo .thumb', _this).attr('href', _imgAttr[2] + '?p=' + _index);
        });
      });
    });


  }

  // object move while sp
  let _timer = false;
  if ( _timer !== false ) { clearTimeout(_timer); }
  $(window).on('load resize', function(){
    _timer = setTimeout( () => {
      if( $(window).innerWidth() < 640 ) {
        $('.gallery-within-article').insertAfter('.article-body p:eq(0)');
      } else {
        $('.gallery-within-article').appendTo('.article-media-wrap');
      }
    }, 0);
  });
}
let _anchor = ($) => {
  let _url  = new URL(location);
  let _gh_H = Math.floor( $('.global-header').outerHeight() ) || 0;

  $(document).on('click', '[href*="#"]', (cl) => {
    let _href = $(cl.currentTarget).attr('href');
    let _target = _href.split('#').pop();
    if ( _target ) {
      $('body,html').animate({ scrollTop: $('#' + _target).offset().top - (_gh_H / 4) }, 500, 'easeInOutExpo');
    }
    return false;
  });
  if( /#/.test(_url) ) {
    var _target = _url.href.split('#').pop();
    setTimeout( function() {
      if ( _target ) {
        $('body,html').animate({ scrollTop: $('#' + _target).offset().top - (_gh_H / 4)}, 500, 'easeInOutExpo');
      }
      return false;
    }, 500);
  }
}
let _tab_carousel = ($) => {
  var _ctab = $('.cat-tabs ul');
  var _currentNum = _ctab.find('li.current').index();

  if ( $('.cat-tabs').length > 0 ) {
    var _ctabsTarget = $('.cat-tabs');
    let _ctabsOptions = {
      type: 'slide',
      autoWidth: true,
      perMove: 1,
      perPage: 1,
      dragMinThreshold: 5,
      easing: 'ease-out',
      arrows: true,
      pagination: false,
      gap: '0.2rem',
      mediaQuery: 'min',
      trimSpace: 'move',
      start: _currentNum,
      focus: 'center',
      padding: { left: 0, right: '0.5rem' },
      breakpoints: {
        641: {
          arrows: false,
          gap: '0',
        },
        1281: {
          gap: '0.5rem',
        }
      },
    }
    for ( var i = 0; i < _ctabsTarget.length; i++ ) {
      new Splide(_ctabsTarget[i], _ctabsOptions).mount();
    }
  }
}
let _ticker = ($) => {
  if ( $('.tic-links').length > 0 ) {
    let _tickerTarget = '.tic-links';
    let _tickerOptions = {
      type: 'loop',
      autoWidth: true,
      perPage: 1,
      arrows: true,
      pagination: false,
      drag: true,
      mediaQuery: 'min',
      direction: 'ttb',
      height: '2em',
      autoplay: true,
      interval: 3000,
      classes: {
        arrows: 'splide__arrows splide__arrows--ttb spin_wrap',
        arrow: 'splide__arrow spin',
        prev: 'splide__arrow--prev up',
        next: 'splide__arrow--next dn',
      },
    }
    let _tickerSplide = new Splide(_tickerTarget, _tickerOptions);
    _tickerSplide.mount();
  }
}
